import { Head } from '@inertiajs/react';
import React from 'react';

export default function FancyHead() {
  return (
    <Head>
      <link
        rel="icon"
        type="image/svg+xml"
        href={'/favicon.svg?v=' + Date.now()}
      />
      {/*<link rel="icon" type="image/png" href={'/favicon.png?v=' + Date.now()} />*/}
      {/*<link rel="alternate icon" href="/favicon.ico" />*/}
      {/*<link rel="icon" sizes="32x32" href="/favicon-32x32.ico" />*/}
      {/*<link rel="icon" sizes="16x16" href="/favicon-16x16.ico" />*/}
      {/*<link rel="icon" sizes="48x48" href="/favicon-48x48.ico" />*/}
    </Head>
  );
}
